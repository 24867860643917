html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.viewport-header {
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.videoTag {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

h1 {
  font-family: 'Syncopate', sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3vw;
  line-height: 1.2;
  font-size: 5vw;
  text-align: center;
}

h1 span {
  display: block;
  font-size: 5vw;
  letter-spacing: -0.4vw;
  color: #B27707;
}

h1 .normal-text {
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.4;
  font-size: 14px;
  font-weight: 100;
  margin: 20px auto;
  padding: 20px;
  max-width: 750px;
}

h1 .normal-text-bigger {
  /* text-transform: none; */
  letter-spacing: 0;
  line-height: 1.4;
  font-size: 14px;
}


.profiles {
  margin: 30px 50px;
}
.profiles img {
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.avatarLink {
  margin: 12px;
  display: inline-block;
}